<template>
	<v-sheet class="create-Customer create-page" id="customer" style="margin: 0 -12px">
		<div class="container">
			<v-row class="sticky">
				<v-col md="6" class="my-auto">
					<h1 class="custom-header-blue-text m-0">{{ pageTitle }}</h1>
				</v-col>
				<v-col md="6" class="text-right">
					<v-btn
						:loading="pageLoading"
						class="white--text mr-2"
						depressed
						color="blue darken-4"
						tile
						:disabled="pageLoading || !formValid"
						v-on:click="createOrUpdateCustomer()"
					>
						Save
					</v-btn>
					<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
				</v-col>
			</v-row>
			<v-form
				ref="customer_submit"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="createOrUpdateCustomer"
			>
				<v-row class="px-4 mt-2 grey lighten-4">
					<v-col>
						<v-row class="mb-3 px-4" v-if="internal == true">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Customer</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="customerData"
									hide-details
									outlined
									placeholder="Customer"
									v-model="project.customer"
									item-text="company_name"
									item-value="id"
									v-on:change="getCustomerSalesPeson($event)"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Sales Person</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="salesPserson"
									hide-details
									outlined
									placeholder="Sales Persons"
									v-model="project.user"
									item-text="display_name"
									item-value="id"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Contract</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<!-- <v-autocomplete
									:items="customerContract"
									hide-details
									outlined
									placeholder="Contract"
									v-model="project.contract"
									item-text="barcode"
									item-value="id"
								>
								</v-autocomplete> -->
								<v-autocomplete
									:items="customerContract"
									hide-details
									outlined
									placeholder="Contract"
									v-model="project.contract"
									item-text="title"
									item-value="id"
									:rules="[vrules.required(project.contract, 'Contract')]"
									:class="{
										required: !project.contract,
									}"
								>
									<template v-slot:selection="data">
										<v-chip
											small
											v-bind="data.attrs"
											:input-value="data.selected"
											close
											@click="data.select"
											@click:close="remove(data.item)"
										>
											<!-- <v-avatar left>
											<v-img :src="data.item.profile_img"></v-img>
										</v-avatar> -->
											<span>
												{{ data.item.barcode }}
											</span>
											{{ data.item.title }}
										</v-chip>
									</template>
									<template v-slot:item="data">
										<template>
											<!-- <v-list-item-title>
											<img :src="data.item.title" />
										</v-list-item-title> -->
											<v-list-item-content>
												<v-list-item-title>{{ data.item.title }}({{ data.item.barcode }})</v-list-item-title>
												<!-- <v-list-item-title v-html="data.item.title"></v-list-item-title> -->
											</v-list-item-content>
										</template>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="4" cols="12" class="my-auto py-0">
								<label class="input-label required">Project Type</label>
							</v-col>
							<v-col lg="8" class="my-auto py-0">
								<v-autocomplete
									:items="projectTypeList"
									hide-details
									outlined
									placeholder="Project Type"
									v-model="project.type"
									item-text="name"
									item-value="value"
								>
								</v-autocomplete>
							</v-col>
						</v-row>
						<template v-if="project.type == 'seo'">
							<div class="form-grp-title d-flex">
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto px-0"> SEO</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-radio-group
											v-model="project.seo_type"
											row
											hide-details
											v-on:change="resetSeoList($event)"
											class="mt-0"
										>
											<v-radio label="Combined" value="combined"></v-radio>
											<v-radio label="Single" value="single"></v-radio>
										</v-radio-group>
									</v-col>
								</v-row>
							</div>
							<div>
								<template v-for="(data, index) in project.customer_services">
									<div :key="`index${index}`" class="parent-element">
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Keyword</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-row>
													<v-col cols="4" md="3" class="py-0">
														<v-text-field
															v-model="project.customer_services[index].keyword_monthly"
															outlined
															hide-details
															placeholder="Keyword"
															v-on:change="dateFormated(project.customer_services[index], index)"
														>
														</v-text-field>
													</v-col>
													<div class="my-auto">+</div>
													<v-col cols="4" md="3" class="py-0">
														<v-text-field
															v-model="project.customer_services[index].keyword_free"
															outlined
															hide-details
															placeholder="Free"
															v-on:keyup="dateFormated(project.customer_services[index], index)"
														>
														</v-text-field>
													</v-col>
												</v-row>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Duration</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-row>
													<v-col cols="4" md="3" class="py-0">
														<v-autocomplete
															:items="months"
															item-text="text"
															item-value="value"
															hide-details
															outlined
															placeholder="Month"
															v-model="project.customer_services[index].total_time"
															v-on:change="dateFormated(project.customer_services[index], index)"
														>
														</v-autocomplete>
													</v-col>
													<div class="my-auto">+</div>
													<v-col cols="4" md="3" class="py-0">
														<v-text-field
															v-model="project.customer_services[index].time_free"
															outlined
															hide-details
															placeholder="Free"
															v-on:keyup="dateFormated(project.customer_services[index], index)"
														>
														</v-text-field>
													</v-col>
												</v-row>
											</v-col>
										</v-row>
										<v-row class="px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Dates</label>
											</v-col>
											<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
											<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
											<v-col lg="2" class="my-auto py-0"
												><label class="input-label">Extend Free Date</label></v-col
											>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
											<v-col lg="3" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													:disabled="pageLoading"
													:loading="pageLoading"
													id="project-date"
													placeholder="Start Date"
													v-model="project.customer_services[index].select_date"
													v-on:change="dateFormated(project.customer_services[index], index)"
												>
												</DatePicker>
											</v-col>
											<v-col lg="3" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													:disabled="pageLoading"
													:loading="pageLoading"
													id="project-date"
													placeholder="End Date"
													v-model="project.customer_services[index].total_time_end"
												>
												</DatePicker>
											</v-col>
											<v-col lg="2" class="my-auto py-0">
												<DatePicker
													hide-details
													clearable
													:disabled="pageLoading"
													:loading="pageLoading"
													id="project-date"
													placeholder="Extend Date"
													v-model="project.customer_services[index].time_free_end"
												>
												</DatePicker>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Cost</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0">
												<v-text-field
													hide-details
													outlined
													class="currency-input"
													placeholder="Cost"
													type="number"
													min="0"
													max="9999"
													prepend-inner-icon="mdi-currency-usd"
													v-model.trim.number.lazy="project.customer_services[index].amount"
													v-on:keypress="limitDecimal($event, project.customer_services[index].amount)"
												></v-text-field>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Payment Terms</label>
											</v-col>
											<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
												<v-autocomplete
													:items="payment_list"
													item-text="text"
													item-value="id"
													hide-details
													outlined
													placeholder="Payment Terms"
													v-model="project.customer_services[index].payment_terms"
												>
												</v-autocomplete>
												<CreateServer
													:title="'Payment Terms'"
													:type-id="52"
													v-on:success="getSettings"
												></CreateServer>
											</v-col>
										</v-row>
										<v-row class="mb-3 px-4">
											<v-col lg="4" cols="12" class="my-auto py-0">
												<label class="input-label">Remark </label>
											</v-col>

											<v-col lg="8" class="my-auto py-0">
												<TextAreaInput
													:rows="4"
													dense
													ref="comment"
													hide-details
													v-model="project.customer_services[index].remark"
													placeholder="Enter Remark..."
													:disabled="pageLoading"
													:loading="pageLoading"
												></TextAreaInput>
											</v-col>
										</v-row>

										<div
											v-for="(child_data, child_index) in data.customer_urls"
											:key="`child_index${child_index}`"
										>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Urls</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0">
													<v-text-field
														hide-details
														outlined
														placeholder="Urls"
														v-model="project.customer_services[index].customer_urls[child_index].website_urls"
													>
													</v-text-field>
												</v-col>
											</v-row>
											<v-row class="mb-3 px-4">
												<v-col lg="4" cols="12" class="my-auto py-0">
													<label class="input-label">Target Google</label>
												</v-col>
												<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
													<v-autocomplete
														:items="target_list"
														item-text="text"
														item-value="id"
														hide-details
														outlined
														placeholder="Target Google"
														v-model="
															project.customer_services[index].customer_urls[child_index].url_target_google
														"
													>
													</v-autocomplete>
													<CreateServer
														:title="'Target Google'"
														:type-id="53"
														v-on:success="getSettings"
													></CreateServer>
												</v-col>
											</v-row>
											<template v-if="project.seo_type == 'combined'">
												<template
													v-for="(kpi_file, index) in project.customer_services[index].customer_urls[child_index]
														.keyword_files"
												>
													<v-row class="mb-3 px-4" :key="index">
														<v-col lg="4" cols="12" class="my-auto py-0">
															<label class="input-label">keyword file</label>
														</v-col>
														<v-col lg="4" cols="4" class="my-auto py-0 d-flex align-items-center">
															<v-file-input
																:id="`document-file-${index}`"
																placeholder="Select File"
																outlined
																class="mt-3"
																prepend-icon=""
																prepend-inner-icon="mdi-attachment"
																hide-details
																v-model="kpi_file.file"
																v-on:change="
																	updateFile(
																		index,
																		$event,
																		'keyword',
																		project.customer_services[index].customer_urls[child_index].keyword_files
																	)
																"
																v-on:click:clear="
																	updateFile(
																		index,
																		$event,
																		'keyword',
																		project.customer_services[index].customer_urls[child_index].keyword_files
																	)
																"
															></v-file-input>
														</v-col>
														<v-col lg="4" cols="4" class="my-auto py-0 d-flex align-items-center">
															<TextInput
																:id="`document-name-${index}`"
																v-model="kpi_file.name"
																hide-details
																placeholder="File Name"
																:suffix="kpi_file.suffix"
															></TextInput>
														</v-col>
													</v-row>
												</template>
											</template>
											<v-row
												class="mb-3 px-4"
												v-if="
													project &&
													project.customer_services &&
													project.customer_services[0] &&
													project.customer_services[0].customer_urls &&
													project.customer_services[0].customer_urls.length > 1 &&
													child_index > 0
												"
											>
												<v-col lg="4" cols="12" class="my-auto py-0">
													<v-btn
														tile
														depressed
														color="red white--text"
														v-on:click="
															removeSeoField(
																child_index,
																project.customer_services[0].customer_urls[child_index].id
															)
														"
													>
														<v-icon>mdi-delete</v-icon>
														Remove
													</v-btn>
												</v-col>
											</v-row>
											<v-col
												cols="12"
												class="pt-0"
												v-if="
													project &&
													project.customer_services &&
													project.customer_services[0] &&
													project.customer_services[0].customer_urls &&
													project.customer_services[0].customer_urls.length > 1
												"
											>
												<v-divider></v-divider>
											</v-col>
										</div>
										<v-row
											class="mb-3 px-4"
											v-if="
												project &&
												project.customer_services &&
												project.customer_services.length > 1 &&
												index > 0
											"
										>
											<v-col lg="4" cols="12" class="my-auto py-0">
												<template v-if="projectId > 0">
													<v-btn
														tile
														depressed
														color="red white--text"
														v-on:click="deleteServices(project.customer_services[index].id, index)"
													>
														<v-icon>mdi-delete</v-icon>
														Remove
													</v-btn>
												</template>
												<template v-else>
													<v-btn tile depressed color="red white--text" v-on:click="removeSeoField(index)">
														<v-icon>mdi-delete</v-icon>
														Remove
													</v-btn>
												</template>
											</v-col>
										</v-row>
										<v-col
											cols="12"
											class="pt-0"
											v-if="project && project.customer_services && project.customer_services.length > 1"
										>
											<v-divider></v-divider>
										</v-col>
									</div>
								</template>

								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<v-btn tile depressed color="blue darken-4 white--text" v-on:click="addSeoField()">
											<v-icon>mdi-plus</v-icon>
											Add more
										</v-btn>
									</v-col>
								</v-row>
							</div>
						</template>
						<template v-if="project.type == 'hosting'">
							<div class="form-grp-title d-flex">
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto px-0"> Hosting</v-col>
									<v-col lg="8" class="my-auto py-0">
										<template
											v-if="project && project.hosting && project.hosting[0] && project.hosting[0].type"
										>
											<v-radio-group
												v-model="project.hosting[0].type"
												row
												hide-details
												class="mt-0"
												v-on:change="clearhosting($event)"
											>
												<v-radio label="Client" value="client"></v-radio>
												<v-radio label="Bthrust" value="bthrust"></v-radio>
											</v-radio-group>
										</template>
									</v-col>
								</v-row>
							</div>

							<template
								v-if="
									project &&
									project.hosting &&
									project.hosting[0] &&
									project.hosting[0].type &&
									project.hosting[0].type == 'client'
								"
							>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Cpanel/Server URL</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											outlined
											hide-details
											placeholder="Cpanel/Server URL"
											v-model="project.hosting[0].cpanal_url"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Dates</label>
									</v-col>
									<v-col lg="2" class="my-auto py-0"><label class="input-label">Duration</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
									<v-col lg="2" class="my-auto py-0">
										<v-autocomplete
											:items="months"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Month"
											v-model="project.hosting[0].duration"
											v-on:change="dateFormated(project.hosting[0], 'hosting')"
										>
										</v-autocomplete>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="Start Date"
											v-model="project.hosting[0].start_date"
											v-on:change="dateFormated(project.hosting[0], 'hosting')"
										>
										</DatePicker>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="End Date"
											v-model="project.hosting[0].end_date"
										></DatePicker>
									</v-col>
								</v-row>

								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Remark </label>
									</v-col>

									<v-col lg="8" class="my-auto py-0">
										<TextAreaInput
											:rows="4"
											dense
											ref="comment"
											hide-details
											v-model="project.hosting[0].remark"
											placeholder="Enter Remark..."
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
								</v-row>
							</template>
							<template
								v-if="
									project &&
									project.hosting &&
									project.hosting[0] &&
									project.hosting[0].type &&
									project.hosting[0].type == 'bthrust'
								"
							>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Service Provider</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
										<v-autocomplete
											:items="serverProvider"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Service Provider"
											v-model="project.hosting[0].service_provider"
										>
										</v-autocomplete>
										<CreateServer
											:title="'Service Provider'"
											:type-id="50"
											v-on:success="getSettings"
										></CreateServer>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Server</label>
									</v-col>

									<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
										<v-autocomplete
											:items="serverWeb"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Server"
											v-model="project.hosting[0].server"
										>
										</v-autocomplete>
										<CreateServer :title="'Server'" :type-id="51" v-on:success="getSettings"></CreateServer>
									</v-col>
								</v-row>
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Dates</label>
									</v-col>
									<v-col lg="2" class="my-auto py-0"><label class="input-label">Duration</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
									<v-col lg="2" class="my-auto py-0">
										<v-autocomplete
											:items="months"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Month"
											v-model="project.hosting[0].duration"
											v-on:change="dateFormated(project.hosting[0], 'hosting')"
										>
										</v-autocomplete>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="Start Date"
											v-model="project.hosting[0].start_date"
											v-on:change="dateFormated(project.hosting[0], 'hosting')"
										>
										</DatePicker>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="End Date"
											v-model="project.hosting[0].end_date"
										></DatePicker>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Cost</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											class="currency-input"
											placeholder="Cost"
											type="number"
											min="0"
											max="9999"
											prepend-inner-icon="mdi-currency-usd"
											v-model.trim.number.lazy="project.hosting[0].cost"
											v-on:keypress="limitDecimal($event, project.hosting[0].cost)"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Remark </label>
									</v-col>

									<v-col lg="8" class="my-auto py-0">
										<TextAreaInput
											:rows="4"
											dense
											ref="comment"
											hide-details
											v-model="project.hosting[0].remark"
											placeholder="Enter Remark..."
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
								</v-row>
							</template>
						</template>
						<template v-if="project.type == 'ssl'">
							<div class="form-grp-title d-flex">
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto px-0"> SSL </v-col>
									<v-col lg="8" class="my-auto py-0">
										<template v-if="project && project.ssl && project.ssl[0] && project.ssl[0].type">
											<v-radio-group
												v-model="project.ssl[0].type"
												row
												hide-details
												class="mt-0"
												v-on:change="clearSsl($event)"
											>
												<v-radio label="Client" value="client"></v-radio>
												<v-radio label="Bthrust" value="bthrust"></v-radio>
											</v-radio-group>
										</template>
									</v-col>
								</v-row>
							</div>

							<template
								v-if="
									project.ssl && project.ssl[0] && project.ssl[0].type && project.ssl[0].type == 'client'
								"
							>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Service Provider</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
										<v-autocomplete
											:items="serverProvider"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Service Provider"
											v-model="project.ssl[0].service_provider"
										>
										</v-autocomplete>
										<CreateServer
											:title="'Service Provider'"
											:type-id="50"
											v-on:success="getSettings"
										></CreateServer>
									</v-col>
								</v-row>
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Dates</label>
									</v-col>
									<v-col lg="2" class="my-auto py-0"><label class="input-label">Duration</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
									<v-col lg="2" class="my-auto py-0">
										<v-autocomplete
											:items="months"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Month"
											v-model="project.ssl[0].duration"
											v-on:change="dateFormated(project.ssl[0], 'ssl')"
										>
										</v-autocomplete>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="Start Date"
											v-model="project.ssl[0].start_date"
											v-on:change="dateFormated(project.ssl[0], 'ssl')"
										>
										</DatePicker>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="End Date"
											v-model="project.ssl[0].end_date"
										></DatePicker>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Remark </label>
									</v-col>

									<v-col lg="8" class="my-auto py-0">
										<TextAreaInput
											:rows="4"
											dense
											ref="comment"
											hide-details
											v-model="project.ssl[0].remark"
											placeholder="Enter Remark..."
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
								</v-row>
							</template>
							<template
								v-if="
									project &&
									project.ssl &&
									project.ssl[0] &&
									project.ssl[0].type &&
									project.ssl[0].type == 'bthrust'
								"
							>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Service Provider</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0 pr-10 position-relative">
										<v-autocomplete
											:items="serverProvider"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Service Provider"
											v-model="project.ssl[0].service_provider"
										>
										</v-autocomplete>
										<CreateServer
											:title="'Service Provider'"
											:type-id="50"
											v-on:success="getSettings"
										></CreateServer>
									</v-col>
								</v-row>
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Dates</label>
									</v-col>
									<v-col lg="2" class="my-auto py-0"><label class="input-label">Duration</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
									<v-col lg="2" class="my-auto py-0">
										<v-autocomplete
											:items="months"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Month"
											v-model="project.ssl[0].duration"
											v-on:change="dateFormated(project.ssl[0], 'ssl')"
										>
										</v-autocomplete>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="Start Date"
											v-model="project.ssl[0].start_date"
											v-on:change="dateFormated(project.ssl[0], 'ssl')"
										>
										</DatePicker>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="End Date"
											v-model="project.ssl[0].end_date"
										></DatePicker>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Cost</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											hide-details
											outlined
											class="currency-input"
											placeholder="Cost"
											type="number"
											min="0"
											max="9999"
											prepend-inner-icon="mdi-currency-usd"
											v-model.trim.number.lazy="project.ssl[0].cost"
											v-on:keypress="limitDecimal($event, project.ssl[0].cost)"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Remark </label>
									</v-col>

									<v-col lg="8" class="my-auto py-0">
										<TextAreaInput
											:rows="4"
											dense
											ref="comment"
											hide-details
											v-model="project.ssl[0].remark"
											placeholder="Enter Remark..."
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
								</v-row>
							</template>
						</template>
						<template v-if="project.type == 'web'">
							<div class="form-grp-title d-flex">
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto px-0"> Web </v-col>
									<v-col lg="8" class="my-auto py-0">
										<template v-if="project && project.web && project.web[0] && project.web[0].type">
											<v-radio-group
												v-model="project.web[0].type"
												row
												hide-details
												class="mt-0"
												v-on:change="clearWeb($event)"
											>
												<v-radio label="Client" value="client"></v-radio>
												<v-radio label="Bthrust" value="bthrust"></v-radio>
											</v-radio-group>
										</template>
									</v-col>
								</v-row>
							</div>
							<template
								v-if="
									project.web && project.web[0] && project.web[0].type && project.web[0].type == 'client'
								"
							>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">CMS URL</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											outlined
											hide-details
											placeholder="CMS URL"
											v-model="project.web[0].cms_url"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Remark </label>
									</v-col>

									<v-col lg="8" class="my-auto py-0">
										<TextAreaInput
											:rows="4"
											dense
											ref="comment"
											hide-details
											v-model="project.web[0].remark"
											placeholder="Enter Remark..."
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Notes</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<label class="input-label" style="color: red !important"
											>Crediential's shared over email and chat</label
										>
									</v-col>
								</v-row>
							</template>
							<template
								v-if="
									project.web && project.web[0] && project.web[0].type && project.web[0].type == 'bthrust'
								"
							>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Domain</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field outlined hide-details placeholder="Domain" v-model="project.web[0].domain">
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">CMS</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field outlined hide-details placeholder="CMS" v-model="project.web[0].cms">
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">CMS URL</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											outlined
											hide-details
											placeholder="CMS URL"
											v-model="project.web[0].cms_url"
										>
										</v-text-field>
									</v-col>
								</v-row>

								<v-row class="mb-3 px-4" v-if="project.billing_type == 'multiple_billing'">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Cost</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											outlined
											hide-details
											type="Number"
											placeholder="Cost"
											v-model="project.web[0].cost"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Dates</label>
									</v-col>
									<v-col lg="2" class="my-auto py-0"><label class="input-label">Duration</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
									<v-col lg="2" class="my-auto py-0">
										<v-autocomplete
											:items="months"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Month"
											v-model="project.web[0].duration"
											v-on:change="dateFormated(project.web[0], 'web')"
										>
										</v-autocomplete>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="Start Date"
											v-model="project.web[0].start_date"
											v-on:change="dateFormated(project.web[0], 'web')"
										>
										</DatePicker>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="End Date"
											v-model="project.web[0].end_date"
										></DatePicker>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Remark </label>
									</v-col>

									<v-col lg="8" class="my-auto py-0">
										<TextAreaInput
											:rows="4"
											dense
											ref="comment"
											hide-details
											v-model="project.web[0].remark"
											placeholder="Enter Remark..."
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
								</v-row>
							</template>
						</template>
						<template v-if="project.type == 'domain'">
							<div class="form-grp-title d-flex">
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto px-0"> Domain </v-col>
									<v-col lg="8" class="my-auto py-0">
										<template v-if="project && project.domain && project.domain[0] && project.domain[0].type">
											<v-radio-group
												v-model="project.domain[0].type"
												row
												hide-details
												class="mt-0"
												v-on:change="clearDomain($event)"
											>
												<v-radio label="Client" value="client"></v-radio>
												<v-radio label="Bthrust" value="bthrust"></v-radio>
											</v-radio-group>
										</template>
									</v-col>
								</v-row>
							</div>

							<template
								v-if="
									project &&
									project.domain[0] &&
									project.domain[0].type &&
									project.domain[0].type == 'client'
								"
							>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Name</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field outlined hide-details placeholder="Name" v-model="project.domain[0].name">
										</v-text-field>
									</v-col>
								</v-row>

								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Dates</label>
									</v-col>
									<v-col lg="2" class="my-auto py-0"><label class="input-label">Duration</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
									<v-col lg="2" class="my-auto py-0">
										<v-autocomplete
											:items="months"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Month"
											v-model="project.domain[0].duration"
											v-on:change="dateFormated(project.domain[0], 'domain')"
										>
										</v-autocomplete>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="Start Date"
											v-model="project.domain[0].start_date"
											v-on:change="dateFormated(project.domain[0], 'domain')"
										>
										</DatePicker>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="End Date"
											v-model="project.domain[0].end_date"
										></DatePicker>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Remark </label>
									</v-col>

									<v-col lg="8" class="my-auto py-0">
										<TextAreaInput
											:rows="4"
											dense
											ref="comment"
											hide-details
											v-model="project.domain[0].remark"
											placeholder="Enter Remark..."
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
								</v-row>
							</template>
							<template
								v-if="
									project.domain &&
									project.domain[0] &&
									project.domain[0].type &&
									project.domain[0].type == 'bthrust'
								"
							>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Name</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field outlined hide-details placeholder="Name" v-model="project.domain[0].name">
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Cost</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<v-text-field
											outlined
											type="number"
											hide-details
											placeholder="Cost"
											v-model="project.domain[0].cost"
										>
										</v-text-field>
									</v-col>
								</v-row>
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Dates</label>
									</v-col>
									<v-col lg="2" class="my-auto py-0"><label class="input-label">Duration</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
									<v-col lg="2" class="my-auto py-0">
										<v-autocomplete
											:items="months"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Month"
											v-model="project.domain[0].duration"
											v-on:change="dateFormated(project.domain[0], 'domain')"
										>
										</v-autocomplete>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="Start Date"
											v-model="project.domain[0].start_date"
											v-on:change="dateFormated(project.domain[0], 'domain')"
										>
										</DatePicker>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="End Date"
											v-model="project.domain[0].end_date"
										></DatePicker>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Remark </label>
									</v-col>

									<v-col lg="8" class="my-auto py-0">
										<TextAreaInput
											:rows="4"
											dense
											ref="comment"
											hide-details
											v-model="project.domain[0].remark"
											placeholder="Enter Remark..."
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
								</v-row>
							</template>
						</template>
						<template v-if="project.type == 'maintenance'">
							<div class="form-grp-title d-flex">
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto px-0"> Maintenance</v-col>
									<v-col lg="8" class="my-auto py-0">
										<template
											v-if="
												project && project.maintenance && project.maintenance[0] && project.maintenance[0].type
											"
										>
											<v-radio-group v-model="project.maintenance[0].type" row hide-details class="mt-0">
												<v-radio label="Client" value="client"></v-radio>
												<v-radio label="Bthrust" value="bthrust"></v-radio>
											</v-radio-group>
										</template>
									</v-col>
								</v-row>
							</div>

							<template
								v-if="project && project.maintenance[0] && project.maintenance[0].type == 'client'"
							>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label" style="color: red !important">Notes</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<label class="input-label" style="color: red !important">No information available</label>
									</v-col>
								</v-row>
							</template>

							<template
								v-if="
									project.maintenance &&
									project.maintenance[0] &&
									project.maintenance[0].type &&
									project.maintenance[0].type == 'bthrust'
								"
							>
								<v-row class="px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Dates</label>
									</v-col>
									<v-col lg="2" class="my-auto py-0"><label class="input-label">Duration</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
									<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0"> </v-col>
									<v-col lg="2" class="my-auto py-0">
										<v-autocomplete
											:items="months"
											item-text="text"
											item-value="value"
											hide-details
											outlined
											placeholder="Month"
											v-model="project.maintenance[0].duration"
											v-on:change="dateFormated(project.maintenance[0], 'maintenance')"
										>
										</v-autocomplete>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="Start Date"
											v-model="project.maintenance[0].start_date"
											v-on:change="dateFormated(project.maintenance[0], 'maintenance')"
										>
										</DatePicker>
									</v-col>
									<v-col lg="3" class="my-auto py-0">
										<DatePicker
											hide-details
											clearable
											:disabled="pageLoading"
											:loading="pageLoading"
											id="start-date"
											placeholder="End Date"
											v-model="project.maintenance[0].end_date"
										></DatePicker>
									</v-col>
								</v-row>
								<v-row class="mb-3 px-4">
									<v-col lg="4" cols="12" class="my-auto py-0">
										<label class="input-label">Remark</label>
									</v-col>
									<v-col lg="8" class="my-auto py-0">
										<TextAreaInput
											:rows="4"
											dense
											ref="comment"
											hide-details
											v-model="project.maintenance[0].remark"
											placeholder="Enter Remark..."
											:disabled="pageLoading"
											:loading="pageLoading"
										></TextAreaInput>
									</v-col>
								</v-row>
							</template>
						</template>
					</v-col>
				</v-row>
			</v-form>
		</div>
	</v-sheet>
</template>

<script>
import { mapGetters } from "vuex";
import { POST, GET, DELETE } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import MomentJS from "moment-timezone";
import CreateServer from "@/view/pages/leads/create/create-server";
import DatePicker from "@/view/components/DatePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
import TextInput from "@/view/components/TextInput";
import { toSafeInteger } from "lodash";
/* import { ProjectEventBus } from "@/core/lib/leave.form.lib"; */

export default {
	mixins: [MainMixin],
	name: "Create-Customer",
	props: {
		projectDialog: {
			type: Boolean,
			default: false,
		},

		customerId: {
			type: Number,
			default: 0,
		},
		projectId: {
			type: Number,
			default: 0,
		},
		internal: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			pageTitle: "Create New Customer",
			formValid: true,
			today: new Date(),
			date_menu: false,
			/* projectId: null, */
			contact_period_list: [
				{ value: 1, name: "One Monthly" },
				{ value: 2, name: "Quarterly" },
				{ value: 3, name: "Half Yearly" },
				{ value: 4, name: "Yearly" },
			],
			leadData: [],
			pageLoading: false,
			currencyList: [],
			//	openUploadModel:false,
			full_rating: 4,
			base_only_rating: 2,
			renew_rating: 3,
			project_date_menu: false,
			seo_date_menu: false,
			seo_free_date_menu: false,
			seo_end_date_menu: false,
			sales_date_menu: false,
			dataTab: "overviwe",
			checkData: [],
			usersList: [],
			preHostingType: null,
			preSslType: null,
			preWebType: null,
			preDomainType: null,
			preMainType: null,
			preSeoType: null,
			lead_id: 0,
			checkpayload: false,
			months: [
				{ value: "1", text: "1" },
				{ value: "2", text: "2" },
				{ value: "3", text: "3" },
				{ value: "4", text: "4" },
				{ value: "5", text: "5" },
				{ value: "6", text: "6" },
				{ value: "7", text: "7" },
				{ value: "8", text: "8" },
				{ value: "9", text: "9" },
				{ value: "10", text: "10" },
				{ value: "11", text: "11" },
				{ value: "12", text: "12" },
			],
			payment_list: [
				/* { value: "1", text: "Yearly" },
				{ value: "2", text: "Half Yearly" },
				{ value: "3", text: "Monthly" }, */
			],
			target_list: [],
			salesPserson: [],
			customerData: [],
			customerContract: [],
			/* project:{
				type:'seo',
				user:0,
			}, */
			project: {
				type: "seo",
				user: 0,
				contract: 0,
				customer: 0,
				seo_type: "combined",
				hosting: [
					{
						cpanal_url: null,
						duration: null,
						remark: null,
						cost: 0,
						start_date: null,
						end_date: null,
						cms: null,
						cms_url: null,
						server: null,
						service_provider: null,
						type: "client",
						related_type: 0,
						project: 0,
						is_filld: false,
					},
				],
				ssl: [
					{
						server: null,
						service_provider: null,
						type: "client",
						duration: null,
						cost: 0,
						remark: null,
						related_type: 0,
						project: 0,
						start_date: null,
						end_date: null,
					},
				],
				web: [
					{
						domain: null,
						remark: null,
						duration: null,
						cms: null,
						cms_url: null,
						type: "bthrust",
						cost: 0,
						related_type: 0,
						project: 0,
						start_date: null,
						end_date: null,
					},
				],
				domain: [
					{
						name: null,
						cost: 0,
						type: "client",
						related_type: 0,
						project: 0,
						start_date: null,
						end_date: null,
						remark: null,
						duration: null,
					},
				],
				maintenance: [
					{
						type: "client",
						duration: null,
						related_type: 0,
						project: 0,
						//cost:0,
						start_date: null,
						end_date: null,
						remark: null,
					},
				],
				customer_services: [
					{
						id: 0,
						keyword_monthly: null,
						keyword_free: null,
						total_time: null,
						time_free: null,
						select_date: null,
						total_time_end: null,
						time_free_end: null,
						amount: 0,
						payment_terms: 0,
						remark: null,
						customer_urls: [
							{
								website_urls: null,
								url_target_google: 0,
								keyword_files: [
									{
										file: null,
										name: null,
									},
								],
							},
						],
					},
				],
			},

			statusList: [],
			countryList: [],
			//fileList: [],
			files: [
				{
					file: null,
					name: null,
				},
			],
			phoneCodeList: [],
			projectTypeList: [
				{ value: "seo", name: "Seo" },
				{ value: "web", name: "Web" },
				{ value: "domain", name: "Domain" },
				{ value: "hosting", name: "Hosting" },
				{ value: "ssl", name: "SSL" },
				{ value: "maintenance", name: "Maintenance" },
			],
			designationList: [],
			serverProvider: [],
			serverWeb: [],
		};
	},
	watch: {
		projectId(projectId) {
			if (projectId) {
				this.getproject();
			}
		},
		projectDialog(projectDialog) {
			if (projectDialog && this.customerId) {
				this.getCustomerSalesPeson();
				this.getCustomer();
			}
		},
	},
	/* props:{
		customerId: {
			type: Number,
			default: 0,
		},
	}, */
	components: {
		CreateServer,
		DatePicker,
		TextAreaInput,
		TextInput,
	},

	methods: {
		/* uploadDialog() {
			this.openUploadModel = true;
		}, */

		getproject() {
			this.$store
				.dispatch(GET, {
					url: `single-project/${this.projectId}`,
				})
				.then((data) => {
					this.project.user = data.project.user;
					this.project.contract = data.project.contract;
					this.project.type = data.project.type;
					this.project.customer = data.project.customer;
					this.project.seo_type = data.project.seo_type;
					this.project.seo_type = data.project.seo_type;
					this.project.project_month = data.project.project_month;
					this.project.project_start_date = data.project.project_start_date;
					this.project.project_end_date = data.project.project_end_date;
					this.project.sales_date = data.project.sales_date;
					this.project.invoice_date = data.project.invoice_date;
					this.project.priod_month = toSafeInteger(data.project.priod_month);
					this.project.invoice_start_date = data.project.invoice_start_date;
					this.project.invoice_end_date = data.project.invoice_end_date;
					this.project.customer_services = data.customer_services;
					if (this.customerId == 0) {
						this.customerId = data.project.customer;
						this.getCustomerSalesPeson();
					}
					if (this.project.customer_services.length) {
						/* alert(this.customer.customer_services[0].customer_urls.length) */
						this.project.customer_services = data.customer_services;
						if (this.project.customer_services[0].customer_urls.length > 0) {
							this.project.customer_services[0].customer_urls[0].keyword_files = [
								{
									customer_urls: [
										{
											keyword_files: [
												{
													file: null,
													name: null,
												},
											],
										},
									],
								},
							];
						} else {
							this.project.customer_services[0].customer_urls = [
								{
									website_urls: null,
									url_target_google: null,
									keyword_files: [
										{
											file: null,
											name: null,
										},
									],
								},
							];
						}
					} else {
						this.project.customer_services = [
							{
								keyword_monthly: null,
								keyword_free: null,
								total_time: null,
								time_free: null,
								select_date: null,
								total_time_end: null,
								time_free_end: null,
								amount: null,
								payment_terms: 0,
								remark: null,
								customer_urls: [
									{
										website_urls: null,
										url_target_google: null,
										keyword_files: [
											{
												file: null,
												name: null,
											},
										],
									},
								],
							},
						];
					}
					this.project.hosting = data.customer_hosting;
					if (this.project.hosting.length) {
						this.project.hosting = data.customer_hosting;
					} else {
						this.project.hosting = [
							{
								cpanal_url: null,
								start_date: null,
								end_date: null,
								duration: null,
								remark: null,
								cost: 0,
								cms: null,
								cms_url: null,
								server: null,
								service_provider: null,
								type: "client",
								related_type: 0,
								customer: 0,
							},
						];
					}
					this.project.web = data.customer_web;
					if (this.project.web.length) {
						this.project.web = data.customer_web;
					} else {
						this.project.web = [
							{
								domain: null,
								remark: null,
								cms: null,
								cms_url: null,
								type: "bthrust",
								related_type: 0,
								customer: 0,
								cost: 0,
								duration: null,
								start_date: null,
								end_date: null,
							},
						];
					}
					this.project.ssl = data.customer_ssl;
					if (this.project.ssl.length) {
						this.project.ssl = data.customer_ssl;
					} else {
						this.project.ssl = [
							{
								server: null,
								service_provider: null,
								type: "client",
								related_type: 0,
								customer: 0,
								start_date: null,
								end_date: null,
								duration: null,
								remark: null,
								cost: 0,
							},
						];
					}
					this.project.domain = data.domain;
					if (this.project.domain.length) {
						this.project.domain = data.domain;
					} else {
						this.project.domain = [
							{
								name: null,
								duration: null,
								remark: null,
								type: "bthrust",
								related_type: 0,
								customer: 0,
								start_date: null,
								end_date: null,
							},
						];
					}

					this.project.maintenance = data.maintenance;

					if (this.project.maintenance.length) {
						this.project.maintenance = data.maintenance;
					} else {
						this.project.maintenance = [
							{
								type: "client",
								related_type: 0,
								customer: 0,
								start_date: null,
								duration: null,
								end_date: null,
								cost: 0,
								remark: null,
							},
						];
					}
					this.preHostingType = data.customer_hosting[0].type;
					this.preSslType = data.customer_ssl[0].type;
					this.preWebType = data.customer_web[0].type;

					this.preDomainType = data.domain[0].type;
					this.preMainType = data.maintenance[0].type;
					this.preSeoType = data.project.seo_type;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		get_contact_period_values() {
			if (this.project.invoice_start_date) {
				let contactPeriodParseDate = MomentJS(this.project.invoice_start_date);

				if (this.project.priod_month == 4) {
					/* Yearly */
					contactPeriodParseDate = MomentJS(contactPeriodParseDate).add(1, "years");
				}

				if (this.project.priod_month == 3) {
					/* Half Yearly*/
					contactPeriodParseDate = MomentJS(contactPeriodParseDate).add(6, "months");
				}

				if (this.project.priod_month == 2) {
					/*Quarterly */
					contactPeriodParseDate = MomentJS(contactPeriodParseDate).add(3, "months");
				}

				if (this.project.priod_month == 1) {
					/* Monthly */

					contactPeriodParseDate = MomentJS(contactPeriodParseDate).add(1, "months");
				}

				return (this.project.invoice_end_date = contactPeriodParseDate.format("YYYY-MM-DD"));
			}
		},
		dateFormated(data, index) {
			if (data.select_date) {
				let date = MomentJS(data.select_date, "YYYY-MM-DD");
				date.add(data.total_time, "months");
				this.project.customer_services[index].total_time_end = date.format("YYYY-MM-DD");
				if (data.time_free) {
					let add = toSafeInteger(data.time_free) + toSafeInteger(data.total_time);
					let date = MomentJS(data.select_date, "YYYY-MM-DD");
					date.add(add, "months");
					this.project.customer_services[index].time_free_end = date.format("YYYY-MM-DD");
				}
			}
			if (data.start_date && index == "hosting") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				this.project.hosting[0].end_date = date.format("YYYY-MM-DD");
			}
			if (data.start_date && index == "ssl") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				this.project.ssl[0].end_date = date.format("YYYY-MM-DD");
			}
			if (data.start_date && index == "web") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				this.project.web[0].end_date = date.format("YYYY-MM-DD");
			}
			if (data.start_date && index == "domain") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				this.project.domain[0].end_date = date.format("YYYY-MM-DD");
			}
			if (data.start_date && index == "maintenance") {
				let date = MomentJS(data.start_date, "YYYY-MM-DD");
				date.add(data.duration, "months");
				this.project.maintenance[0].end_date = date.format("YYYY-MM-DD");
			}
			if (data.project_start_date && index == "project") {
				let date = MomentJS(data.project_start_date, "YYYY-MM-DD");
				date.add(data.project_month, "months");
				this.project.project_end_date = date.format("YYYY-MM-DD");
			}
		},

		hostingFuncyion(value) {
			this.dataTab = value;

			switch (value) {
				case "ssl":
					this.project.ssl[0].type = "client";
					break;
				case "hosting":
					this.project.hosting[0].type = "client";
					break;
				case "web":
					this.project.web[0].type = "bthrust";
					break;
				case "domain":
					this.project.domain[0].type = "client";
					break;
				case "maintenance":
					this.project.maintenance[0].type = "bthrust";
					break;
				case "seo":
					this.project.seo_type = "combined";
					break;
			}
		},

		deleteServices(id, index) {
			if (id > 0) {
				this.$store
					.dispatch(DELETE, {
						url: `customer-service/${id}`,
					})
					.then(() => {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! Customer service deleted successfully.",
							},
						]);
						this.getCustomer();
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			} else {
				this.project.customer_services.splice(index, 1);
			}
		},
		createOrUpdateCustomer() {
			const formErrors = this.validateForm(this.$refs["customer_submit"]);

			this.$refs["customer_submit"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["customer_submit"].validate()) {
				return false;
			}

			this.pageLoading = true;

			let formData = new FormData();

			formData.append("type", this.project.type ? this.project.type : null);
			formData.append("contract", this.project.contract ? this.project.contract : 0);
			formData.append("customer", this.customerId ? this.customerId : this.project.customer);
			formData.append("user", this.project.user ? this.project.user : 0);
			formData.append("seo_type", this.project.seo_type ? this.project.seo_type : null);

			if (this.project.type == "hosting") {
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].cpanal_url
				) {
					formData.append("hosting[0][cpanal_url]", this.project.hosting[0].cpanal_url);
				}
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].duration
				) {
					formData.append("hosting[0][duration]", this.project.hosting[0].duration);
				}
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].start_date
				) {
					formData.append("hosting[0][start_date]", this.project.hosting[0].start_date);
				}
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].end_date
				) {
					formData.append("hosting[0][end_date]", this.project.hosting[0].end_date);
				}
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].cms
				) {
					formData.append("hosting[0][cms]", this.project.hosting[0].cms);
				}
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].cost
				) {
					formData.append("hosting[0][cost]", this.project.hosting[0].cost);
				}
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].remark
				) {
					formData.append("hosting[0][remark]", this.project.hosting[0].remark);
				}

				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].cms_url
				) {
					formData.append("hosting[0][cms_url]", this.project.hosting[0].cms_url);
				}
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].server
				) {
					formData.append("hosting[0][server]", this.project.hosting[0].server);
				}
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].service_provider
				) {
					formData.append("hosting[0][service_provider]", this.project.hosting[0].service_provider);
				}
				if (
					this.project &&
					this.project.hosting &&
					this.project.hosting[0] &&
					this.project.hosting[0].type
				) {
					formData.append("hosting[0][type]", this.project.hosting[0].type);
				}
			}

			if (this.project.type == "ssl") {
				/*  if(this.project && this.project.ssl && this.project.ssl[0] && this.project.ssl[0].server){
				formData.append("ssl[0][server]", this.project.ssl[0].server);
			   } */
				if (
					this.project &&
					this.project.ssl &&
					this.project.ssl[0] &&
					this.project.ssl[0].service_provider
				) {
					formData.append("ssl[0][service_provider]", this.project.ssl[0].service_provider);
				}
				if (this.project && this.project.ssl && this.project.ssl[0] && this.project.ssl[0].type) {
					formData.append("ssl[0][type]", this.project.ssl[0].type);
				}
				if (this.project && this.project.ssl && this.project.ssl[0] && this.project.ssl[0].start_date) {
					formData.append("ssl[0][start_date]", this.project.ssl[0].start_date);
				}
				if (this.project && this.project.ssl && this.project.ssl[0] && this.project.ssl[0].end_date) {
					formData.append("ssl[0][end_date]", this.project.ssl[0].end_date);
				}
				if (this.project && this.project.ssl && this.project.ssl[0] && this.project.ssl[0].cost) {
					formData.append("ssl[0][cost]", this.project.ssl[0].cost);
				}
				if (this.project && this.project.ssl && this.project.ssl[0] && this.project.ssl[0].duration) {
					formData.append("ssl[0][duration]", this.project.ssl[0].duration);
				}
				if (this.project && this.project.ssl && this.project.ssl[0] && this.project.ssl[0].remark) {
					formData.append("ssl[0][remark]", this.project.ssl[0].remark);
				}
			}

			if (this.project.type == "web") {
				/* if(this.project && this.project.web && this.project.web[0] && this.project.web[0].server_url){
					formData.append("web[0][server_url]", this.project.web[0].server_url);
				} */
				if (this.project && this.project.web && this.project.web[0] && this.project.web[0].remark) {
					formData.append("web[0][remark]", this.project.web[0].remark);
				}
				if (this.project && this.project.web && this.project.web[0] && this.project.web[0].cms) {
					formData.append("web[0][cms]", this.project.web[0].cms);
				}
				if (this.project && this.project.web && this.project.web[0] && this.project.web[0].cms_url) {
					formData.append("web[0][cms_url]", this.project.web[0].cms_url);
				}
				if (this.project && this.project.web && this.project.web[0] && this.project.web[0].duration) {
					formData.append("web[0][duration]", this.project.web[0].duration);
				}
				if (this.project && this.project.web && this.project.web[0] && this.project.web[0].domain) {
					formData.append("web[0][domain]", this.project.web[0].domain);
				}
				if (this.project && this.project.web && this.project.web[0] && this.project.web[0].cost) {
					formData.append("web[0][cost]", this.project.web[0].cost);
				}
				if (this.project && this.project.web && this.project.web[0] && this.project.web[0].type) {
					formData.append("web[0][type]", this.project.web[0].type);
				}
				if (this.project && this.project.web && this.project.web[0] && this.project.web[0].start_date) {
					formData.append("web[0][start_date]", this.project.web[0].start_date);
				}
				if (this.project && this.project.web && this.project.web[0] && this.project.web[0].end_date) {
					formData.append("web[0][end_date]", this.project.web[0].end_date);
				}
			}

			if (this.project.type == "domain") {
				if (
					this.project &&
					this.project.domain &&
					this.project.domain[0] &&
					this.project.domain[0].name
				) {
					formData.append("domain[0][name]", this.project.domain[0].name);
				}
				if (
					this.project &&
					this.project.domain &&
					this.project.domain[0] &&
					this.project.domain[0].cost
				) {
					formData.append("domain[0][cost]", this.project.domain[0].cost);
				}

				if (
					this.project &&
					this.project.domain &&
					this.project.domain[0] &&
					this.project.domain[0].duration
				) {
					formData.append("domain[0][duration]", this.project.domain[0].duration);
				}
				if (
					this.project &&
					this.project.domain &&
					this.project.domain[0] &&
					this.project.domain[0].remark
				) {
					formData.append("domain[0][remark]", this.project.domain[0].remark);
				}

				if (
					this.project &&
					this.project.domain &&
					this.project.domain[0] &&
					this.project.domain[0].type
				) {
					formData.append("domain[0][type]", this.project.domain[0].type);
				}
				if (
					this.project &&
					this.project.domain &&
					this.project.domain[0] &&
					this.project.domain[0].start_date
				) {
					formData.append("domain[0][start_date]", this.project.domain[0].start_date);
				}
				if (
					this.project &&
					this.project.domain &&
					this.project.domain[0] &&
					this.project.domain[0].end_date
				) {
					formData.append("domain[0][end_date]", this.project.domain[0].end_date);
				}
			}

			if (this.project.type == "maintenance") {
				if (
					this.project &&
					this.project.maintenance &&
					this.project.maintenance[0] &&
					this.project.maintenance[0].type
				) {
					formData.append("maintenance[0][type]", this.project.maintenance[0].type);
				}
				if (
					this.project &&
					this.project.maintenance &&
					this.project.maintenance[0] &&
					this.project.maintenance[0].duration
				) {
					formData.append("maintenance[0][duration]", this.project.maintenance[0].duration);
				}
				if (
					this.project &&
					this.project.maintenance &&
					this.project.maintenance[0] &&
					this.project.maintenance[0].start_date
				) {
					formData.append("maintenance[0][start_date]", this.project.maintenance[0].start_date);
				}
				if (
					this.project &&
					this.project.maintenance &&
					this.project.maintenance[0] &&
					this.project.maintenance[0].end_date
				) {
					formData.append("maintenance[0][end_date]", this.project.maintenance[0].end_date);
				}

				if (
					this.project &&
					this.project.maintenance &&
					this.project.maintenance[0] &&
					this.project.maintenance[0].remark
				) {
					formData.append("maintenance[0][remark]", this.project.maintenance[0].remark);
				}
			}

			if (this.project.type == "seo") {
				for (let i = 0; i < this.project.customer_services.length; i++) {
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].id
					) {
						formData.append(`customer_services[${i}][id]`, this.project.customer_services[i].id);
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].keyword_monthly
					) {
						formData.append(
							`customer_services[${i}][keyword_monthly]`,
							this.project.customer_services[i].keyword_monthly
						);
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].keyword_free
					) {
						formData.append(
							`customer_services[${i}][keyword_free]`,
							this.project.customer_services[i].keyword_free
						);
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].total_time
					) {
						formData.append(
							`customer_services[${i}][total_time]`,
							this.project.customer_services[i].total_time
						);
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].time_free
					) {
						formData.append(
							`customer_services[${i}][time_free]`,
							this.project.customer_services[i].time_free
						);
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].select_date
					) {
						formData.append(
							`customer_services[${i}][select_date]`,
							this.project.customer_services[i].select_date
						);
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].total_time_end
					) {
						formData.append(
							`customer_services[${i}][total_time_end]`,
							this.project.customer_services[i].total_time_end
						);
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].time_free_end
					) {
						formData.append(
							`customer_services[${i}][time_free_end]`,
							this.project.customer_services[i].time_free_end
						);
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].amount
					) {
						formData.append(`customer_services[${i}][amount]`, this.project.customer_services[i].amount);
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].payment_terms
					) {
						if (this.project.customer_services[i].payment_terms.id) {
							formData.append(
								`customer_services[${i}][payment_terms]`,
								this.project.customer_services[i].payment_terms.id
							);
						} else {
							formData.append(
								`customer_services[${i}][payment_terms]`,
								this.project.customer_services[i].payment_terms
							);
						}
					}
					if (
						this.project &&
						this.project.customer_services &&
						this.project.customer_services[i] &&
						this.project.customer_services[i].remark
					) {
						formData.append(`customer_services[${i}][remark]`, this.project.customer_services[i].remark);
					}

					for (let j = 0; j < this.project.customer_services[i].customer_urls.length; j++) {
						if (
							this.project &&
							this.project.customer_services &&
							this.project.customer_services[i] &&
							this.project.customer_services[i].customer_urls &&
							this.project.customer_services[i].customer_urls[j] &&
							this.project.customer_services[i].customer_urls[j].website_urls
						) {
							formData.append(
								`customer_services[${i}][customer_urls][${j}][website_urls]`,
								this.project.customer_services[i].customer_urls[j].website_urls
							);
						}
						if (
							this.project &&
							this.project.customer_services &&
							this.project.customer_services[i] &&
							this.project.customer_services[i].customer_urls &&
							this.project.customer_services[i].customer_urls[j] &&
							this.project.customer_services[i].customer_urls[j].url_target_google
							/* this.project.customer_services[i].customer_urls[j].url_target_google.id */
						) {
							if (this.project.customer_services[i].customer_urls[j].url_target_google.id) {
								formData.append(
									`customer_services[${i}][customer_urls][${j}][url_target_google]`,
									this.project.customer_services[i].customer_urls[j].url_target_google.id
								);
							} else {
								formData.append(
									`customer_services[${i}][customer_urls][${j}][url_target_google]`,
									this.project.customer_services[i].customer_urls[j].url_target_google
								);
							}
						} /* else{
							formData.append(
								`customer_services[${i}][customer_urls][${j}][url_target_google]`,
								this.project.customer_services[i].customer_urls[j].url_target_google
							);
						} */
						if (
							this.project &&
							this.project.customer_services &&
							this.project.customer_services[i] &&
							this.project.customer_services[i].customer_urls &&
							this.project.customer_services[i].customer_urls[j] &&
							this.project.customer_services[i].customer_urls[j].keyword_files
						) {
							if (
								this.project.customer_services[i].customer_urls[j].keyword_files[0] &&
								this.project.customer_services[i].customer_urls[j].keyword_files[0].file
							) {
								formData.append(
									`customer_services[${i}][customer_urls][${j}][keyword_files][0][file]`,
									this.project.customer_services[i].customer_urls[j].keyword_files[0].file
								);
								formData.append(
									`customer_services[${i}][customer_urls][${j}][keyword_files][0][name]`,
									this.project.customer_services[i].customer_urls[j].keyword_files[0].name
								);
							}
						}
					}
				}
			}

			let requestTYPE = POST;
			let requestURL = "project";

			if (this.projectId > 0) {
				requestURL = `project/${this.projectId}`;
			}

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					if (this.projectId > 0) {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! project updated successfully.",
							},
						]);
					} else {
						this.$store.commit(SET_MESSAGE, [
							{
								model: true,
								message: "Success ! project created successfully.",
							},
						]);
					}
					this.$emit("refress", true);
					/* 	this.$emit("updateData", true); */
					this.$refs["customer_submit"].reset();
					/* this.$router.replace({
						name: "customer-detail2",
					}); */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		remove(item) {
			const index = this.project.contract.indexOf(item.id);
			this.project.contract.splice(index, 1);
		},
		updateFile(index, file, value, data) {
			if (value == "keyword") {
				if (file && file.name) {
					data[index].name = file.name.split(".").slice(0, -1).join(".");
					data[index].suffix = `.${file.name.split(".").pop()}`;
				} else {
					data[index].name = null;
					data[index].suffix = null;
				}
			} else {
				if (file && file.name) {
					this.files[index].name = file.name.split(".").slice(0, -1).join(".");
					this.files[index].suffix = `.${file.name.split(".").pop()}`;
				} else {
					this.files[index].name = null;
					this.files[index].suffix = null;
				}
			}
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: "customer-setting",
				})
				.then((data) => {
					(_this.usersList = data.allUsers), (_this.countryList = data.country);
					_this.currencyList = data.currency;
					_this.phoneCodeList = data.code;
					_this.designationList = data.designation;
					_this.serverProvider = data.serverProvider;
					_this.serverWeb = data.server;
					_this.payment_list = data.payment_list;
					_this.target_list = data.target_list;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCustomerSalesPeson(data) {
			const _this = this;
			if (data > 0) {
				this.customerId = data;
			}
			_this.$store
				.dispatch(GET, {
					url: `customer-sales/${this.customerId}`,
				})
				.then((data) => {
					console.log(data);
					_this.salesPserson = data.attendie[0].attendies;
					_this.customerContract = data.customer_contract;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getCustomer() {
			this.$store
				.dispatch(GET, {
					url: `customer`,
				})
				.then((data) => {
					this.customerData = data.tbody;
					this.getCustomerSalesPeson();
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		/* uploadFile(data) {
			console.log(data)
			this.fileList.push(data);
		}, */
		/* selectFile() {
			this.$refs["uploadFile"].$refs["input"].click();
		}, */

		/* remove_file(index) {
			this.fileList.splice(index, 1);
		}, */
		addSeoField() {
			if (this.project.seo_type == "single") {
				const customer_services_obj = {
					keyword_monthly: null,
					keyword_free: null,
					total_time: null,
					time_free: null,
					select_date: null,
					total_time_end: null,
					time_free_end: null,
					amount: null,
					payment_terms: 0,
					remark: null,
					customer_urls: [
						{
							url: null,
							target_google: null,
							keyword_files: [
								{
									file: null,
									name: null,
								},
							],
						},
					],
				};
				this.project.customer_services.push(customer_services_obj);
			}
			if (this.project.seo_type == "combined") {
				const customer_urls_obj = {
					url: null,
					target_google: null,
					keyword_files: [
						{
							file: null,
							name: null,
						},
					],
				};
				if (this.project.customer_services[0].customer_urls) {
					this.project.customer_services[0].customer_urls.push(customer_urls_obj);
				} else {
					this.project.customer_services[0].customer_urls = customer_urls_obj;
				}
			}
		},
		removeSeoField(index, id) {
			if (id > 0) {
				this.$store
					.dispatch(DELETE, {
						url: `customer-url/${id}`,
					})
					.then(() => {
						this.getCustomer();
					})
					.catch((error) => {
						console.log({
							error,
						});
					});
			}
			if (this.project.seo_type == "single") {
				this.project.customer_services.splice(index, 1);
			}
			if (this.project.seo_type == "combined") {
				this.project.customer_services[0].customer_urls.splice(index, 1);
			}
		},
		resetSeoList(value) {
			if (this.preSeoType && this.preSeoType == value) {
				this.project.customer_services = this.checkData.customer_services;
				console.log(this.project.customer_services);
			} else {
				this.project.customer_services = [
					{
						keyword_monthly: null,
						keyword_free: null,
						total_time: null,
						time_free: null,
						select_date: null,
						total_time_end: null,
						time_free_end: null,
						amount: null,
						payment_terms: 0,
						remark: null,
						customer_urls: [
							{
								url: null,
								target_google: null,
							},
						],
					},
				];
				if (value == "single") {
					this.project.customer_services[0].keyword_monthly = null;
					this.project.customer_services[0].keyword_free = null;
					this.project.customer_services[0].total_time = null;
					this.project.customer_services[0].time_free = null;
					this.project.customer_services[0].select_date = null;
					this.project.customer_services[0].total_time_end = null;
					this.project.customer_services[0].time_free_end = null;
					this.project.customer_services[0].amount = 0;
					this.project.customer_services[0].payment_terms = 0;
					this.project.customer_services[0].remark = null;
					this.project.customer_services[0].customer_urls[0].url = null;
					this.project.customer_services[0].customer_urls[0].target_google = null;
				}
			}
		},
		clearhosting(value) {
			if (this.preHostingType && this.preHostingType == value) {
				this.project.hosting = this.checkData.customer_hosting;
				this.project.hosting[0].type = value;
				console.log(this.project.hosting);
			} else {
				this.project.hosting = [
					{
						cpanal_url: null,
						duration: null,
						remark: null,
						start_date: null,
						end_date: null,
						cms: null,
						cms_url: null,
						server: null,
						service_provider: null,
						type: value == "client" ? "client" : "bthrust",
						related_type: 0,
						customer: 0,
						is_filld: false,
					},
				];
				if (value == "bthrust") {
					this.project.hosting[0].cost = 0;
				}
			}
		},

		clearSsl(value) {
			if (this.preSslType && this.preSslType == value) {
				this.project.ssl = this.checkData.customer_ssl;
				this.project.ssl[0].type = value;

				console.log(this.project.ssl);
			} else {
				this.project.ssl = [
					{
						related_type: 0,
						customer: 0,
						start_date: null,
						end_date: null,
						duration: null,
						remark: null,
						cost: 0,
						type: value == "client" ? "client" : "bthrust",
					},
				];
				if (value == "bthrust") {
					this.project.ssl[0].server = null;
					this.project.ssl[0].service_provider = null;
				}
			}
		},

		clearWeb(value) {
			if (this.preWebType && this.preWebType == value) {
				this.project.web = this.checkData.customer_web;
				console.log(this.project.web);
				this.project.web[0].type = value;

				console.log(this.project.web);
			} else {
				this.project.web = [
					{
						cms_url: null,
						type: value == "client" ? "client" : "bthrust",
					},
				];
				if (value == "bthrust") {
					this.project.web[0].remark = null;
					this.project.web[0].domain = null;
					this.project.web[0].cms = null;
					this.project.web[0].cms_url = null;
					this.project.web[0].cost = 0;
					this.project.web[0].related_type = 0;
					this.project.web[0].customer = 0;
					this.project.web[0].start_date = null;
					this.project.web[0].end_date = null;
				}
			}
		},

		clearDomain(value) {
			if (this.preDomainType && this.preDomainType == value) {
				this.project.domain = this.checkData.domain;
				this.project.domain[0].type = value;

				console.log(this.project.domain);
			} else {
				this.project.domain = [
					{
						name: null,
						type: value == "client" ? "client" : "bthrust",
						related_type: 0,
						customer: 0,
						start_date: null,
						end_date: null,
						remark: null,
						duration: null,
					},
				];
				if (value == "bthrust") {
					this.project.domain[0].cost = 0;
				}
			}
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {
		this.getSettings();
		if (this.contractId) {
			this.getCustomer();
			this.pageTitle = "Update Customer";
		}
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Dashboard", disabled: false, href: "/dashboard" },
			{ text: "Customer", disabled: false, href: "/contacts/customer" },
			{ text: "Create", disabled: true },
		]);

		if (this.contractId) {
			this.$store.dispatch(SET_BREADCRUMB, [
				{ text: "Dashboard", disabled: false, href: "/dashboard" },
				{ text: "Customer", disabled: false, href: "/contacts/customer" },
				{ text: "Update", disabled: true },
			]);
		}
		const { query } = this.$route;
		this.lead_id = query.lead_id;

		if (this.lead_id > 0) {
			this.singleLead();
		}
		this.getCustomerSalesPeson();
		this.getCustomer();
	},
	beforeMount() {
		/* this.contractId = this.$route.params.id; */
	},
};
</script>
